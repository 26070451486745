import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TranslationUtils} from '../../../core/services/translation.utils';
import {Observable} from 'rxjs';
import {Deleteable, Retrieveable, Saveable, Searchable} from '../../../core/services/remote-services-interfaces';
import {Language} from '../../../general/models/language';
import {Course} from '../../models/course';
import {RestClientService} from '../../../core/services/rest-client.service';
import {PagedResult} from '../../models/paged-result';
import {CourseRole} from '../../models/course-role';
import {User} from '../../../core/models/user';
import {LangCurrencyService} from '../../../core-common/services/lang-currency.service';
import {SecurityService} from '../../../core/services/security.service';
import {Unit} from '../../models/unit';

@Injectable({
    providedIn: 'root'
})
export class CourseRemoteService implements Searchable<Language>,
    Retrieveable<Language, string>,
    Saveable<Language>,
    Deleteable<Language, string> {
    private URL = '/api/courses';

    constructor(private restClientService: RestClientService, private translateService: TranslateService,
                private langCurrencyService: LangCurrencyService) {
    }

    search(criteria?: any): Observable<PagedResult<Course>> {
        return this.restClientService.get(this.URL, criteria);
    }

    searchSelect(criteria?: any): Observable<PagedResult<Unit>> {
        return this.restClientService.get(`${this.URL}/select`, criteria);
    }

    get(id: string, includeStudentInfo?, noCurrencyApplied?): Observable<Course> {
        const params = {...this.getCurrencyParam(noCurrencyApplied)};
        if (includeStudentInfo) {
            params.includeStudentInfo = true;
        }
        return this.restClientService.get(`${this.URL}/${id}`, params);
    }

    getMemberCourseRole(id: string, userId: string): Observable<CourseRole> {
        return this.restClientService.get(`${this.URL}/${id}/course-role/${userId}`);
    }

    save(course: Course): Observable<Course> {
        TranslationUtils.processTranslation(course, this.getTranslatableFields(), this.translateService);
        if (course.id) {
            return this.restClientService.put(`${this.URL}/${course.id}`, course);
        }
        return this.restClientService.post(this.URL, course);
    }

    delete(id: string): Observable<any> {
        return this.restClientService.delete(`${this.URL}/${id}`);
    }

    getTranslatableFields(): string[] {
        return ['name', 'summary', 'slogan', 'seoTitle'];
    }

    getCourseByCategory(): Observable<any> {
        return this.restClientService.get(`${this.URL}/student/courseByCategory`);
    }

    getDetail(id: string, includeUnitsCount?: boolean): Observable<any> {
        return this.restClientService.get(`${this.URL}/${id}/detail`, {includeUnitsCount: includeUnitsCount});
    }

    getVideoUrlTest(): Observable<any> {
        return this.restClientService.get('/api/cookies-test/video');
    }

    getTeachers(id: string, courseAnnouncementId: string): Observable<User[]> {
        return this.restClientService.get(`${this.URL}/${id}/teachers`, {
            courseAnnouncementId: courseAnnouncementId
        });
    }

    getCategoriesStructure(id: string): Observable<any[]> {
        return this.restClientService.get(`${this.URL}/${id}/subcategories`);
    }

    getCurrencyParam(noCurrencyApplied?): any {
        return noCurrencyApplied ? {noCurrencyApplied: true} : {currency: this.langCurrencyService.getCurrent().currency};
    }

    getPriceManagement(id: string): Observable<any> {
        return this.restClientService.get(`${this.URL}/price/${id}`);
    }

    updateCoursePrice(id: string, coursePrice: any): Observable<any> {
        return this.restClientService.put(`${this.URL}/price/${id}`, coursePrice/*, {handleErr: true}*/);
    }

    getCourseSeoTitle(id: string): Observable<any> {
        return this.restClientService.get(`${this.URL}/seo/${id}`);
    }

    // updateCourseSeoTitle(id: string, coursePrice: any): Observable<any> {
    //     return this.restClientService.put(`${this.URL}/seo/${id}`, coursePrice/*, {handleErr: true}*/);
    // }

    updateCourseSeoTitle(course: Course): Observable<Course> {
        TranslationUtils.processTranslation(course, this.getTranslatableFields(), this.translateService);
        return this.restClientService.put(`${this.URL}/seo/${course.id}`, course);
    }

    getLastActivities(id: string, courseAnnouncementId: any): Observable<any> {
        return this.restClientService.get(`${this.URL}/${id}/last-activities`,
            {courseAnnouncementId: courseAnnouncementId});
    }

    getActivitiesForum(id: string, unitId: string, page: any, courseAnnouncementId: any): Observable<any> {
        return this.restClientService.get(`${this.URL}/${id}/last-activities-more-info`, {
            unitId: unitId,
            page: page,
            courseAnnouncementId: courseAnnouncementId
        });
    }

    getEvaluationsSummary(id: string, page: any, size: any,  courseAnnouncementId: any): Observable<any> {
        return this.restClientService.get(`${this.URL}/resume-activities/${id}`, {
            size: size,
            page: page,
            courseAnnouncementId: courseAnnouncementId
        });
    }

    resumeExportExcel(courseId: string, courseAnnouncementId: string, filter: string): Observable<Blob> {
        return this.restClientService.getBlob<Blob>(`${this.URL}/exportExcel/${courseId}`, {courseAnnouncementId: courseAnnouncementId, filter: filter});
    }
}
