import {Injectable} from '@angular/core';
import {FuseConfirmDialogComponent} from '../../../../@fuse/components/confirm-dialog/confirm-dialog.component';
import {FValidationErrorDialogComponent} from '../components/f-validation-error-dialog/f-validation-error-dialog.component';
import {FErrorDialogComponent} from '../components/f-error-dialog/f-error-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    private confirmDialog: MatDialogRef<FuseConfirmDialogComponent>;
    private validationErrorDialog: MatDialogRef<FValidationErrorDialogComponent>;
    private errorDialog: MatDialogRef<FErrorDialogComponent>;
    private selectedValue = 'todo';

    constructor(private _matDialog: MatDialog) {
    }

    showConfirmDialog(message?: string, onYes?: (result: any) => void): void {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {
            restoreFocus: false,
            // position: {top: '8%'}
        });

        this.confirmDialog.componentInstance.confirmMessage = message || 'Está seguro que desea continuar?';
        this.confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                onYes(result);
            }
            this.confirmDialog = null;
        });
    }

    showConfirmDialogSabana(message?: string, onYes?: (result: any) => void): void {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {
            restoreFocus: false,
            // position: {top: '8%'}
        });

        this.confirmDialog.componentInstance.confirmMessage = message || 'Está seguro que desea continuar?';
        this.confirmDialog.componentInstance.sabana = true;
        this.confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                onYes(result);
            }
            this.confirmDialog = null;
        });
    }

    showConfirmDialogSabanaAnnouncement(message?: string, onYes?: (result: any) => void): void {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {
            restoreFocus: false,
            // position: {top: '8%'}
        });

        this.confirmDialog.componentInstance.confirmMessage = message || 'Está seguro que desea continuar?';
        this.confirmDialog.componentInstance.sabanaAnnouncement = true;
        this.confirmDialog.componentInstance.selectedValue = this.selectedValue;
        this.confirmDialog.afterClosed().subscribe(result => {
            if (result) {
                onYes(result);
            }
            this.confirmDialog = null;
        });
    }

    showConfirmDialog1(message?: string, title?: string): Promise<any> {
        this.confirmDialog = this._matDialog.open(FuseConfirmDialogComponent, {restoreFocus: false});
        this.confirmDialog.componentInstance.confirmMessage = message || '¿Está seguro que desea continuar?';
        this.confirmDialog.componentInstance.title = title || 'Confirmar';

        return new Promise((resolve, reject) => {
            this.confirmDialog.afterClosed().subscribe(result => {
                resolve(result);
            });
            this.confirmDialog = null;
        });
    }

    showValidationErrorDialog(errors: any): void {
        this.validationErrorDialog = this._matDialog.open(FValidationErrorDialogComponent);
        this.validationErrorDialog.componentInstance.errors = errors;

        this.validationErrorDialog.afterClosed().subscribe(result => {
            this.validationErrorDialog = null;
        });
    }

    showErrorDialog(message: string, title?: string, config: {btnLabel?: string, matDialogConfig: {}} = {btnLabel: 'Cerrar', matDialogConfig: {}}): Promise<void> {
        this.errorDialog = this._matDialog.open(FErrorDialogComponent, {
            minWidth: '350px',
            restoreFocus: false,
            ... config.matDialogConfig
        });

        this.errorDialog.componentInstance.message = message;
        if (title) {
            this.errorDialog.componentInstance.title = title;
        }
        this.errorDialog.componentInstance.btnLabel = config.btnLabel;

        return new Promise((resolve, reject) => {
            this.errorDialog.afterClosed().subscribe(() => {
                this.errorDialog = null;
                resolve();
            });
        });
    }
}
